<template>
  <div>
    <ExamplesBar/>
    <v-container fluid class="mx-auto">
      <carousel-3d :autoplay="true" :autoplay-timeout="8000" :border="0" :space="365">
        <slide :index="0" id="back_slide">
          <div id="link">
                <v-btn
                  elevation="2"
                  block
                  small
                  dark
                  color="rgba(255, 198, 7, 0.8)"
                  class="mx-auto"
                  href="/examples/blend4web/beach_umbrella.html"
                  target="_blank"
                  >
                  <p>view 4D WebGL</p>
                  <v-icon class="ml-auto">
                    mdi-arrow-right-drop-circle-outline
                  </v-icon>
                </v-btn>
            </div>
            <div id="youtube">
              <div>
                <LazyYoutubeVideo src="https://www.youtube.com/embed/faQFfCDegyU" />
              </div>
            </div>
        </slide>
        <slide :index="1" id="back_slide">
          <div id="link">
                <v-btn
                  elevation="2"
                  block
                  small
                  dark
                  color="rgba(255, 198, 7, 0.8)"
                  class="mx-auto"
                  href="/examples/blend4web/cat.html"
                  target="_blank"
                  >
                  <p>view 4D WebGL</p>
                  <v-icon class="ml-auto">
                    mdi-arrow-right-drop-circle-outline
                  </v-icon>
                </v-btn>
            </div>
            <div id="youtube">
              <div>
                <LazyYoutubeVideo src="https://www.youtube.com/embed/VYBKXfLa5H4" />
              </div>
            </div>
        </slide>
        <slide :index="2" id="back_slide">
          <div id="link">
                <v-btn
                  elevation="2"
                  block
                  small
                  dark
                  color="rgba(255, 198, 7, 0.8)"
                  class="mx-auto"
                  href="/examples/blend4web/dof.html"
                  target="_blank"
                  >
                  <p>view 4D WebGL</p>
                  <v-icon class="ml-auto">
                    mdi-arrow-right-drop-circle-outline
                  </v-icon>
                </v-btn>
            </div>
            <div id="youtube">
              <div>
                <LazyYoutubeVideo src="https://www.youtube.com/embed/zKvXJK6Jbko" />
              </div>
            </div>
        </slide>
        <slide :index="3" id="back_slide">
          <div id="link">
                <v-btn
                  elevation="2"
                  block
                  small
                  dark
                  color="rgba(255, 198, 7, 0.8)"
                  class="mx-auto"
                  href="/examples/blend4web/soffit.html"
                  target="_blank"
                  >
                  <p>view 4D WebGL</p>
                  <v-icon class="ml-auto">
                    mdi-arrow-right-drop-circle-outline
                  </v-icon>
                </v-btn>
            </div>
            <div id="youtube">
              <div>
                <LazyYoutubeVideo src="https://www.youtube.com/embed/-bLs8QpBmT8" />
              </div>
            </div>
        </slide>
        <slide :index="4" id="back_slide">
          <div id="link">
                <v-btn
                  elevation="2"
                  block
                  small
                  dark
                  color="rgba(255, 198, 7, 0.8)"
                  class="mx-auto"
                  href="/examples/blend4web/solar_system_ru.html"
                  target="_blank"
                  >
                  <p>view 4D WebGL</p>
                  <v-icon class="ml-auto">
                    mdi-arrow-right-drop-circle-outline
                  </v-icon>
                </v-btn>
            </div>
            <div id="youtube">
              <div>
                <LazyYoutubeVideo src="https://www.youtube.com/embed/keKMFHoC5QY" />
              </div>
            </div>
        </slide>
      </carousel-3d>
      <carousel-3d :autoplay="true" :autoplay-timeout="8000" :border="0" :space="365">
        <slide :index="0" id="back_slide">
          <div id="link">
                <v-btn
                  elevation="2"
                  block
                  small
                  dark
                  color="rgba(255, 198, 7, 0.8)"
                  class="mx-auto"
                  href="/examples/blend4web/forest_on_fire.html"
                  target="_blank"
                  >
                  <p>view 4D WebGL</p>
                  <v-icon class="ml-auto">
                    mdi-arrow-right-drop-circle-outline
                  </v-icon>
                </v-btn>
            </div>
            <div id="youtube">
              <div>
                <LazyYoutubeVideo src="https://www.youtube.com/embed/Hu7otcPcHoc" />
              </div>
            </div>
        </slide>
        <slide :index="1" id="back_slide">
          <div id="link">
                <v-btn
                  elevation="2"
                  block
                  small
                  dark
                  color="rgba(255, 198, 7, 0.8)"
                  class="mx-auto"
                  href="/examples/blend4web/cube_reflections.html"
                  target="_blank"
                  >
                  <p>view 4D WebGL</p>
                  <v-icon class="ml-auto">
                    mdi-arrow-right-drop-circle-outline
                  </v-icon>
                </v-btn>
            </div>
            <div id="youtube">
              <div>
                <LazyYoutubeVideo src="https://www.youtube.com/embed/40ijOmQDf7s" />
              </div>
            </div>
        </slide>
        <slide :index="2" id="back_slide">
          <div id="link">
                <v-btn
                  elevation="2"
                  block
                  small
                  dark
                  color="rgba(255, 198, 7, 0.8)"
                  class="mx-auto"
                  href="/examples/blend4web/bloom.html"
                  target="_blank"
                  >
                  <p>view 4D WebGL</p>
                  <v-icon class="ml-auto">
                    mdi-arrow-right-drop-circle-outline
                  </v-icon>
                </v-btn>
            </div>
            <div id="youtube">
              <div>
                <LazyYoutubeVideo src="https://www.youtube.com/embed/TBxGsbMeoss" />
              </div>
            </div>
        </slide>
        <slide :index="3" id="back_slide">
          <div id="link">
                <v-btn
                  elevation="2"
                  block
                  small
                  dark
                  color="rgba(255, 198, 7, 0.8)"
                  class="mx-auto"
                  href="/examples/blend4web/god_rays.html"
                  target="_blank"
                  >
                  <p>view 4D WebGL</p>
                  <v-icon class="ml-auto">
                    mdi-arrow-right-drop-circle-outline
                  </v-icon>
                </v-btn>
            </div>
            <div id="youtube">
              <div>
                <LazyYoutubeVideo src="https://www.youtube.com/embed/hBY5H0zLs8Y" />
              </div>
            </div>
        </slide>
        <slide :index="4" id="back_slide">
          <div id="link">
                <v-btn
                  elevation="2"
                  block
                  small
                  dark
                  color="rgba(255, 198, 7, 0.8)"
                  class="mx-auto"
                  href="/examples/blend4web/sunglasses.html"
                  target="_blank"
                  >
                  <p>view 4D WebGL</p>
                  <v-icon class="ml-auto">
                    mdi-arrow-right-drop-circle-outline
                  </v-icon>
                </v-btn>
            </div>
            <div id="youtube">
              <div>
                <LazyYoutubeVideo src="https://www.youtube.com/embed/oEiT4RX6WB8" />
              </div>
            </div>
        </slide>
      </carousel-3d>
      <carousel-3d :autoplay="true" :autoplay-timeout="8000" :border="0" :space="365">
        <slide :index="0" id="back_slide">
          <div id="link">
                <v-btn
                  elevation="2"
                  block
                  small
                  dark
                  color="rgba(255, 198, 7, 0.8)"
                  class="mx-auto"
                  href="/examples/blend4web/firstperson.html"
                  target="_blank"
                  >
                  <p>view 4D WebGL</p>
                  <v-icon class="ml-auto">
                    mdi-arrow-right-drop-circle-outline
                  </v-icon>
                </v-btn>
            </div>
            <div id="youtube">
              <div>
                <LazyYoutubeVideo src="https://www.youtube.com/embed/jWWdtJWxQlQ" />
              </div>
            </div>
        </slide>
        <slide :index="1" id="back_slide">
          <div id="link">
                <v-btn
                  elevation="2"
                  block
                  small
                  dark
                  color="rgba(255, 198, 7, 0.8)"
                  class="mx-auto"
                  href="/examples/blend4web/grass.html"
                  target="_blank"
                  >
                  <p>view 4D Webgl</p>
                  <v-icon class="ml-auto">
                    mdi-arrow-right-drop-circle-outline
                  </v-icon>
                </v-btn>
            </div>
            <div id="youtube">
              <div>
                <LazyYoutubeVideo src="https://www.youtube.com/embed/yR43C8SxGkc" />
              </div>
            </div>
        </slide>
        <slide :index="2" id="back_slide">
          <div id="link">
                <v-btn
                  elevation="2"
                  block
                  small
                  dark
                  color="rgba(255, 198, 7, 0.8)"
                  class="mx-auto"
                  href="/examples/blend4web/motion_blur.html"
                  target="_blank"
                  >
                  <p>view 4D WebGL</p>
                  <v-icon class="ml-auto">
                    mdi-arrow-right-drop-circle-outline
                  </v-icon>
                </v-btn>
            </div>
            <div id="youtube">
              <div>
                <LazyYoutubeVideo src="https://www.youtube.com/embed/GHyzFDG41ns" />
              </div>
            </div>
        </slide>
        <slide :index="3" id="back_slide">
          <div id="link">
                <v-btn
                  elevation="2"
                  block
                  small
                  dark
                  color="rgba(255, 198, 7, 0.8)"
                  class="mx-auto"
                  href="/examples/blend4web/ssao.html"
                  target="_blank"
                  >
                  <p>view 4D WebGL</p>
                  <v-icon class="ml-auto">
                    mdi-arrow-right-drop-circle-outline
                  </v-icon>
                </v-btn>
            </div>
            <div id="youtube">
              <div>
                <LazyYoutubeVideo src="https://www.youtube.com/embed/4FucIUng1qk" />
              </div>
            </div>
        </slide>
        <slide :index="4" id="back_slide">
          <div id="link">
                <v-btn
                  elevation="2"
                  block
                  small
                  dark
                  color="rgba(255, 198, 7, 0.8)"
                  class="mx-auto"
                  href="/examples/blend4web/solar_system_en.html"
                  target="_blank"
                  >
                  <p>view 4D WebGL</p>
                  <v-icon class="ml-auto">
                    mdi-arrow-right-drop-circle-outline
                  </v-icon>
                </v-btn>
            </div>
            <div id="youtube">
              <div>
                <LazyYoutubeVideo src="https://www.youtube.com/embed/xxtV3KSg6QY" />
              </div>
            </div>
        </slide>
      </carousel-3d>
      <carousel-3d :autoplay="true" :autoplay-timeout="8000" :border="0" :space="365">
        <slide :index="0" id="back_slide">
          <div id="link">
                <v-btn
                  elevation="2"
                  block
                  small
                  dark
                  color="rgba(255, 198, 7, 0.8)"
                  class="mx-auto"
                  href="/examples/blend4web/flame_webgl.html"
                  target="_blank"
                  >
                  <p>view 4D WebGL</p>
                  <v-icon class="ml-auto">
                    mdi-arrow-right-drop-circle-outline
                  </v-icon>
                </v-btn>
            </div>
            <div id="youtube">
              <div>
                <LazyYoutubeVideo src="https://www.youtube.com/embed/nMLfhwQfflE" />
              </div>
            </div>
        </slide>
        <slide :index="1" id="back_slide">
          <div id="link">
                <v-btn
                  elevation="2"
                  block
                  small
                  dark
                  color="rgba(255, 198, 7, 0.8)"
                  class="mx-auto"
                  href="/examples/blend4web/fontan_webgl.html"
                  target="_blank"
                  >
                  <p>view 4D Webgl</p>
                  <v-icon class="ml-auto">
                    mdi-arrow-right-drop-circle-outline
                  </v-icon>
                </v-btn>
            </div>
            <div id="youtube">
              <div>
                <LazyYoutubeVideo src="https://www.youtube.com/embed/XnPUo595vZk" />
              </div>
            </div>
        </slide>
        <slide :index="2" id="back_slide">
          <div id="link">
                <v-btn
                  elevation="2"
                  block
                  small
                  dark
                  color="rgba(255, 198, 7, 0.8)"
                  class="mx-auto"
                  href="/examples/blend4web/gostinica-vladykino.html"
                  target="_blank"
                  >
                  <p>view 4D WebGL</p>
                  <v-icon class="ml-auto">
                    mdi-arrow-right-drop-circle-outline
                  </v-icon>
                </v-btn>
            </div>
            <div id="youtube">
              <div>
                <LazyYoutubeVideo src="https://www.youtube.com/embed/Ftb8JlNHjoo" />
              </div>
            </div>
        </slide>
        <slide :index="3" id="back_slide">
          <div id="link">
                <v-btn
                  elevation="2"
                  block
                  small
                  dark
                  color="rgba(255, 198, 7, 0.8)"
                  class="mx-auto"
                  href="/examples/blend4web/canvases.html"
                  target="_blank"
                  >
                  <p>view 4D WebGL</p>
                  <v-icon class="ml-auto">
                    mdi-arrow-right-drop-circle-outline
                  </v-icon>
                </v-btn>
            </div>
            <div id="youtube">
              <div>
                <LazyYoutubeVideo src="https://www.youtube.com/embed/mulKUk1bxUY" />
              </div>
            </div>
        </slide>
        <slide :index="4" id="back_slide">
          <div id="link">
                <v-btn
                  elevation="2"
                  block
                  small
                  dark
                  color="rgba(255, 198, 7, 0.8)"
                  class="mx-auto"
                  href="/examples/blend4web/fiktivnye-cveta-iz-dialux-v-blender.html"
                  target="_blank"
                  >
                  <p>view 4D WebGL</p>
                  <v-icon class="ml-auto">
                    mdi-arrow-right-drop-circle-outline
                  </v-icon>
                </v-btn>
            </div>
            <div id="youtube">
              <div>
                <LazyYoutubeVideo src="https://www.youtube.com/embed/nBx_MNXmS_g" />
              </div>
            </div>
        </slide>
      </carousel-3d>
      <carousel-3d :autoplay="true" :autoplay-timeout="8000" :border="0" :space="365">
        <slide :index="0" id="back_slide">
          <div id="link">
                <v-btn
                  elevation="2"
                  block
                  small
                  dark
                  color="rgba(255, 198, 7, 0.8)"
                  class="mx-auto"
                  href="/examples/blend4web/ocean.html"
                  target="_blank"
                  >
                  <p>view 4D WebGL</p>
                  <v-icon class="ml-auto">
                    mdi-arrow-right-drop-circle-outline
                  </v-icon>
                </v-btn>
            </div>
            <div id="youtube">
              <div>
                <LazyYoutubeVideo src="https://www.youtube.com/embed/_OjKXe6Y9zQ" />
              </div>
            </div>
        </slide>
        <slide :index="1" id="back_slide">
          <div id="link">
                <v-btn
                  elevation="2"
                  block
                  small
                  dark
                  color="rgba(255, 198, 7, 0.8)"
                  class="mx-auto"
                  href="/examples/blend4web/ies_minion.html"
                  target="_blank"
                  >
                  <p>view 4D Webgl</p>
                  <v-icon class="ml-auto">
                    mdi-arrow-right-drop-circle-outline
                  </v-icon>
                </v-btn>
            </div>
            <div id="youtube">
              <div>
                <LazyYoutubeVideo src="https://www.youtube.com/embed/NqR61JmPOBE" />
              </div>
            </div>
        </slide>
        <slide :index="2" id="back_slide">
          <div id="link">
                <v-btn
                  elevation="2"
                  block
                  small
                  dark
                  color="rgba(255, 198, 7, 0.8)"
                  class="mx-auto"
                  href="/examples/blend4web/ies_varispot.html"
                  target="_blank"
                  >
                  <p>view 4D WebGL</p>
                  <v-icon class="ml-auto">
                    mdi-arrow-right-drop-circle-outline
                  </v-icon>
                </v-btn>
            </div>
            <div id="youtube">
              <div>
                <LazyYoutubeVideo src="https://www.youtube.com/embed/SXhSV0dcPwo" />
              </div>
            </div>
        </slide>
        <slide :index="3" id="back_slide">
          <div id="link">
                <v-btn
                  elevation="2"
                  block
                  small
                  dark
                  color="rgba(255, 198, 7, 0.8)"
                  class="mx-auto"
                  href="/examples/blend4web/osveshchenie-vhoda-gostinicy-horvatiya.html"
                  target="_blank"
                  >
                  <p>view 4D WebGL</p>
                  <v-icon class="ml-auto">
                    mdi-arrow-right-drop-circle-outline
                  </v-icon>
                </v-btn>
            </div>
            <div id="youtube">
              <div>
                <LazyYoutubeVideo src="https://www.youtube.com/embed/L1YpQ21TVNg" />
              </div>
            </div>
        </slide>
        <slide :index="4" id="back_slide">
          <div id="link">
                <v-btn
                  elevation="2"
                  block
                  small
                  dark
                  color="rgba(255, 198, 7, 0.8)"
                  class="mx-auto"
                  href="/examples/blend4web/ies_facade_turn.html"
                  target="_blank"
                  >
                  <p>view 4D WebGL</p>
                  <v-icon class="ml-auto">
                    mdi-arrow-right-drop-circle-outline
                  </v-icon>
                </v-btn>
            </div>
            <div id="youtube">
              <div>
                <LazyYoutubeVideo src="https://www.youtube.com/embed/dAFc7k_fSxI" />
              </div>
            </div>
        </slide>
      </carousel-3d>
    </v-container>
  </div>
</template>
<script>
import ExamplesBar from '@/components/controls_vue/ExamplesBar.vue';
import { Carousel3d, Slide } from 'vue-carousel-3d';
import LazyYoutubeVideo from "vue-lazy-youtube-video";
  export default {
    name: 'Blend4Web',
    components: {
      Carousel3d,
      Slide,
      LazyYoutubeVideo,
      ExamplesBar,
    },
    data: () => ({
      length: 3,
      onboarding: 0,
    }),

    methods: {
      next () {
        this.onboarding = this.onboarding + 1 === this.length
          ? 0
          : this.onboarding + 1
      },
      prev () {
        this.onboarding = this.onboarding - 1 < 0
          ? this.length - 1
          : this.onboarding - 1
      },
    },
  }
</script>
<style scoped>
#carousel {
  background-color: transparent;
}
#link {
    top: 0;
    left: 0;
    overflow: inherit;
    background: transparent; /* Цвет фона */
}
#youtube {
    top: 10px;
    position: relative;
    padding-bottom: 56.25%; /* задаёт высоту контейнера для 16:9 (если 4:3 — поставьте 75%) */
    height: 0;
    overflow: hidden;
    background-color: transparent;

}
p {
  position: absolute;
  color: #265787;
  margin: 20px;
  opacity: 1;
  transition: opacity 0.6s;
 font-size: 15pt;
 font-family: "ToxicPowers";
}
#back_slide {
  background-color: transparent;
  padding: 10px;

}
@font-face {
font-family: "ToxicPowers";
src: url("/preloader/ToxicPowers.ttf");
/* font-style: normal; */
/* font-weight: normal; */
}
</style>
